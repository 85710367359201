import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { LoginForm } from "../../lib/components/UserAuth";
import { LoginFooter } from "../../lib/components/UserAuth/LoginForm";
import { useAuthContext } from "@fitplan/context/lib-es/auth";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../../components/layout/layout";
import "./Login.scss";
import { navigateTo } from "../../lib/UrlUtils";

export const query = graphql`
  query ($languageISO: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
  }
`;

const Login = ({ data: { ribbon, header, footer } }) => {
  const { t, language } = useTranslation();
  const authContext = useAuthContext();
  const { loggedIn } = authContext;
  useEffect(() => {
    if (loggedIn) {
      return navigateTo("/account");
    }
  }, [])

  return (
    <Layout
      header={header}
      ribbon={ribbon}
      footer={footer}
      hasBanner="white-banner"
    >
      <section className="login-page">
        <div className="login-form-container">
          <h1>{t("loginHeadingText")}</h1>
          <LoginForm
            onComplete={(user) => {
              navigateTo("/account");
            }}
          />
          <LoginFooter />
        </div>
      </section>
    </Layout>
  );
};

export default Login;
